import React from 'react';
import {BrowserRouter as Router, Switch, Route, NavLink} from "react-router-dom";
import './App.css';
import Blog from "./blog/Blog";
import Post from "./blog/Post";
import Writers from "./writers/Writers";
import About from "./about/About";
import Contacts from "./contacts/Contacts";
import NotFound from "./NotFound";
import SectionStab from "./SectionStab";

const isBlog = (_, location) => location.pathname === '/' || location.pathname.indexOf('/blog') === 0;

function App() {
  return (
    <div className="App">
      <Router>
        <header>
          <a className="logo" href="/">
            <img src="/logo.svg" alt="Общественная Алтайская краевая писательская организация «Союз писателей России»" />
          </a>
          <div className="nav">
            <NavLink isActive={isBlog} to="/">Блог</NavLink>
            <NavLink to="/writers">Писатели</NavLink>
            <NavLink to="/memory">Память</NavLink>
            <NavLink to="/library">Библиотека</NavLink>
            <NavLink to="/about">Об организации</NavLink>
            <NavLink to="/contacts">Контакты</NavLink>
          </div>
        </header>
        <div className="main">
          <Switch>
            <Route path="/" exact component={Blog} />
            <Route path="/blog/:postId" exact component={Post} />
            <Route path="/writers/:writerId" render={props => <Writers alive={true} {...props} />} />
            <Route path="/writers" render={props => <Writers alive={true} {...props} />} />
            <Route path="/memory/:writerId" component={Writers} />
            <Route path="/memory" component={Writers} />
            <Route path="/library" component={SectionStab} />
            <Route path="/about" component={About} />
            <Route path="/contacts" component={Contacts} />
            <Route component={NotFound} />
          </Switch>
        </div>
      </Router>
      <div className="copyright">© Общественная Алтайская краевая писательская организация «Союз писателей России», 2020</div>
    </div>
  );
}

export default App;
