import React from 'react';
import './Contacts.css';

function Contacts() {
  return (
    <div className="Contacts">
      <h2>Контакты</h2>
      <div itemScope itemType="http://schema.org/Organization">
        <p><span itemProp="name">Общественная Алтайская краевая писательская организация «Союз писателей России»</span></p>
        <p itemProp="address" itemScope itemType="http://schema.org/PostalAddress">
          Адрес: Алтайский край, г. <span itemProp="addressLocality">Барнаул</span>
        </p>
        <p>Телефон: <a href="tel:+79333113661" itemProp="telephone" target="_blank" rel="noopener noreferrer">8-933-311-3661</a></p>
        <p>Email: <a href="mailto:altay-pisatel@yandex.ru" itemProp="email" target="_blank" rel="noopener noreferrer">altay-pisatel@yandex.ru</a></p>
      </div>
      <p>Ответственный секретарь: Анна Александровна Самойлова</p>
      <p>Совет:</p>
      <ul>
        <li>Дмитрий Владимироваич Каюшкин</li>
        <li>Галина Дмитриевна Колесникова</li>
        <li>Татьяна Николаевна Кузнецова</li>
        <li>Александра Сергеевна Малыгина</li>
        <li>Юлия Анатольевна Нифонтова</li>
        <li>Иван Юрьевич Образцов</li>
        <li>Наталья Михайловна Ревкова</li>
        <li>Сергей Александрович Тепляков</li>
        <li>Владимир Карпович Чикильдик</li>
        <li>Владимир Александрович Шнайдер</li>
      </ul>
      <div className="networkIcons">
        <a href="https://vk.com/agentstvo_neo_lit" target="_blank" rel="noopener noreferrer">
          <img src="/icons/vk.svg" alt="В контакте" />
        </a>
        <a href="https://api.whatsapp.com/send?phone=79333113661" target="_blank" rel="noopener noreferrer">
          <img src="/icons/whatsapp.svg" alt="WhatsApp" />
        </a>
        <a href="https://telegram.im/@anna_kikona" target="_blank" rel="noopener noreferrer">
          <img src="/icons/telegram.svg" alt="Telegram" />
        </a>
      </div>
    </div>
  );
}

export default Contacts;
