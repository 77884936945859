import React, {useState, useEffect} from 'react';
import './Writers.css';
import {NavLink} from "react-router-dom";

function toReadableWriterType(writerType) {
  switch (writerType) {
    case "Poet":
      return "поэт"
    case "Prosaist":
      return "прозаик"
    case "Critic":
      return "критик"
    case "Dramatist":
      return "драматург"
    case "Scenarist":
      return "сценарист"
    case "LocalHistorian":
      return "краевед"
    case "Publicist":
      return "публицист"
    case "Translator":
      return "переводчик"
    default:
      return ''
  }
}

function Writers(props) {
  const [writers, setWriters] = useState([]);
  const [selectedWriter, setSelectedWriter] = useState(null);

  useEffect(() => {
    fetch(`/api/v1/writers`)
      .then(response => response.json())
      .then(json => setWriters(json.writers.map(writer => {
        return {
          ...writer,
          birthDateTime: new Date(writer.birthDateTime),
          deathDateTime: writer.deathDateTime && new Date(writer.deathDateTime),
        }
      }).filter(it => props.alive ? !it.deathDateTime : it.deathDateTime)))
  }, [props.alive]);

  useEffect(() => {
    if (!props.match.params.writerId) {
      setSelectedWriter(null);
      return;
    }

    fetch(`/api/v1/writers/${props.match.params.writerId}`)
      .then(response => response.json())
      .then(json => setSelectedWriter({
        ...json,
        birthDateTime: new Date(json.birthDateTime),
        deathDateTime: json.deathDateTime && new Date(json.deathDateTime),
      }))
  }, [props.match.params.writerId]);

  return (
    <div className="Writers">
      <div className="list">
        {writers.map(writer => (
          <NavLink to={`/${props.alive ? 'writers' : 'memory'}/${writer.slug}`} className="writer" key={writer.slug}>
            <div className="fio">{writer.fio}</div>
            {!props.alive && <div>{writer.birthDateTime.toLocaleDateString("ru-RU")}{writer.deathDateTime && ' — ' + (writer.deathDateTime.getMonth() === 0 && writer.deathDateTime.getDate() === 1 ? writer.deathDateTime.getFullYear() : writer.deathDateTime.toLocaleDateString("ru-RU"))}</div>}
            <div>{writer.types.map(it => toReadableWriterType(it)).join(" | ")}</div>
          </NavLink>
        ))}
      </div>
      <div className="separator" />
      <div className="content">
        {selectedWriter && (
          <>
            <h2>{selectedWriter.fio}</h2>
            <div className="details">
              {props.alive
                ? <div>Год рождения: {selectedWriter.birthDateTime.toLocaleDateString("ru-RU")}</div>
                : <div>Годы жизни: {selectedWriter.birthDateTime.toLocaleDateString("ru-RU")}{selectedWriter.deathDateTime && ' — ' + (selectedWriter.deathDateTime.getMonth() === 0 && selectedWriter.deathDateTime.getDate() === 1 ? selectedWriter.deathDateTime.getFullYear() : selectedWriter.deathDateTime.toLocaleDateString("ru-RU"))}</div>}
              {selectedWriter.enterYear && <div>Год вступления в Союз Писателей: {selectedWriter.enterYear}</div>}
              {selectedWriter.types && selectedWriter.types.length > 0 &&
              <div>{selectedWriter.types.map(it => toReadableWriterType(it)).join(", ")}</div>}
            </div>
            <div dangerouslySetInnerHTML={{__html: selectedWriter.biography}} />
          </>
        )}
      </div>
    </div>
  )
}

export default Writers;
